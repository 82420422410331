{
  "name": "xp-app",
  "version": "0.2.0",
  "scripts": {
    "ng": "ng",
    "sentry-cli": "sentry-cli",
    "start": "ng serve",
    "pwa:start": "npm run build:prod && npm run pwa:serve",
    "pwa:serve": "http-server -c-1 dist/xprivate-pwa",
    "build": "ng build --configuration production",
    "vercel:build:prod": "export VERSION=xp-app@$(node -pe 'require(\"./package.json\").version'); ng build --configuration production && bun run sentry:sourcemaps && SENTRY_ENV=production bun run sentry:release:deploy",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run",
    "postversion": "export VERSION=xp-app@$(echo $npm_package_version); bun run sentry:release:create && bun run sentry:release:set-commits && bun run sentry:release:finalize",
    "sentry:release:set-commits": "sentry-cli releases set-commits $VERSION --auto",
    "sentry:release:create": "sentry-cli releases new -p xp-app $VERSION",
    "sentry:release:finalize": "sentry-cli releases finalize $VERSION",
    "sentry:release:deploy": "sentry-cli releases deploys $VERSION new -e $SENTRY_ENV",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject ./dist/xp-app && sentry-cli sourcemaps upload --release=$VERSION ./dist/xp-app"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.3",
    "@angular/cdk": "^19.0.2",
    "@angular/common": "^19.0.3",
    "@angular/compiler": "^19.0.3",
    "@angular/core": "^19.0.3",
    "@angular/forms": "^19.0.3",
    "@angular/platform-browser": "^19.0.3",
    "@angular/platform-browser-dynamic": "^19.0.3",
    "@angular/router": "^19.0.3",
    "@angular/service-worker": "^19.0.3",
    "@sentry/angular": "^8.42.0",
    "caniuse-lite": "^1.0.30001686",
    "file-saver": "^2.0.5",
    "ngx-filesaver": "^16.0.0",
    "ol": "^7.5.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.4",
    "@angular-devkit/core": "^19.0.4",
    "@angular-devkit/schematics": "^19.0.4",
    "@angular-eslint/builder": "^19.0.0",
    "@angular-eslint/eslint-plugin": "^19.0.0",
    "@angular-eslint/eslint-plugin-template": "^19.0.0",
    "@angular-eslint/schematics": "^19.0.0",
    "@angular-eslint/template-parser": "^19.0.0",
    "@angular/cli": "^19.0.4",
    "@angular/compiler-cli": "^19.0.3",
    "@cypress/schematic": "^2.5.2",
    "@sentry/cli": "^2.39.1",
    "@types/jasmine": "^3.10.18",
    "@types/node": "^22.10.1",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "@typescript-eslint/types": "^8.17.0",
    "@typescript-eslint/utils": "^8.17.0",
    "autoprefixer": "^10.4.20",
    "cypress": "^12.17.4",
    "eslint": "^8.57.1",
    "jasmine-core": "^3.99.1",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^4.0.2",
    "karma-jasmine-html-reporter": "^1.7.0",
    "postcss": "^8.4.49",
    "prettier": "^3.4.2",
    "tailwindcss": "3.3.3",
    "ts-node": "^10.9.2",
    "typescript": "~5.5.4"
  },
  "trustedDependencies": [
    "@parcel/watcher",
    "@sentry/cli",
    "cypress",
    "esbuild",
    "lmdb",
    "msgpackr-extract",
    "nice-napi",
    "nx"
  ]
}
